import React, { useEffect, useState } from 'react';
import { Header } from '@arema/components';

interface HeaderProps{
	text?: string,
	subtext?: string,

}

var ErrorHeader = (props: HeaderProps)=>{
	return <Header
		text={(props.text || "404")}
		subtext={(props.subtext || 'Página no encontrada')}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 10 }}
	/>
}

export default ErrorHeader;