import React, { useEffect, useState } from 'react';
import { Button, Groupper, Header, Image, InstructionsOxxo, InstructionsSPEI, Table } from '@arema/components';
import { useParams } from 'react-router-dom'
import { formatSeatNumber, isJWT } from '@arema/components/Util';
import { ErrorHeader } from '../components';
import { useTitle } from '@arema/components/Hooks';
import { OrderDownload } from '@arema/components/Classes';
import { CDN_URL, PAGE, PAGE_DOWNLOAD, PLACEHOLDER_IMG } from '../TicketsConfig';
import { Link } from 'react-router-dom'
import moment from 'moment';
import API from '../TicketsAPI';
import '../style/tickets_download.scss';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

var TicketDownload = ()=>{
	var { hash } = useParams<{ hash: string }>();
	var [order, setOrder] = useState<OrderDownload>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();

	useEffect(()=>{
		if(!isJWT(hash)) return;
		if(!order){
			API.getTickets(hash).then(res=>{
				if(res.error) return setLoadError(res.message);
				setOrder(res.data);
				setTitle(`Boletos ${res.data.order_hash}`);
			}).catch(err=>{
				return setLoadError('Hubo un error inesperado cargando los boletos (LCL-1)');
			});
		}
	}, [hash]);

	var hasAppleWallet = ()=>{
		return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || navigator.userAgent.includes("Mac");
	}

	if(!isJWT(hash)) return <ErrorHeader />
	if(loadError) return <ErrorHeader text='Error' subtext={loadError} />
	if(!order) return <Header loading text='Cargando boletos' />
	var pending_payments = order.payments.filter(a=>!a.paid && [20, 40].indexOf(a.payment_method)!=-1);
	var DOWNLOAD_ALL = `${PAGE_DOWNLOAD}/tk/${order.download_token}`;
	
	return <div>
		{!!order.tickets && order.tickets.length>0 && (
			<Groupper title='Boletos' titleCentered width={500} className="tickets">
				{order.tickets.length>1 && <>
					<Button className='all' size='big' text='Descargar todos' color='orange' as={Link} asProps={{ to: DOWNLOAD_ALL, target: '_blank' }} />
					<div className="divider dashed"></div>
				</>}
				{order.tickets.map((a,i)=><>
					<div className="ar ticket">
						<div className="info">
							<Image src={`${CDN_URL}/events/${a.event_id}/200.webp`} fallback={PLACEHOLDER_IMG} />
							<div className="data">
								<div className="meta">Boleto {a.ticket_hash}</div>
								<div className="event">{a.event_name}</div>
								<div className="details">
									<b>{a.section_name}</b> - {a.price_name}
								</div>
								{!!a.numbered && (
									<div className="details">
										<b>{a.seat_section || 'Butaca'}</b> {formatSeatNumber(a.seat_row, a.seat_number)}
									</div>
								)}
							</div>
						</div>
						<div className="buttons">
							{hasAppleWallet() && (
								<Link to={`${PAGE_DOWNLOAD}/tks/${a.download_token}?w=1`} target='_blank'>
									<img src={`${PAGE}/apple_wallet.svg`} alt="" />
								</Link>
							)}
							<Button text='Descargar' color='orange' as={Link} asProps={{ to: `${PAGE_DOWNLOAD}/tks/${a.download_token}`, target: '_blank' }} />
						</div>
					</div>
					{i<(order.tickets.length-1) && (
						<div className='divider dashed'></div>
					)}
				</>)}
			</Groupper>
		)}
		{pending_payments.length>0 && (
			(order.tickets.length==0 || order.tickets_total>order.tickets.length) && (
				order.payments.map(a=>(
					<Groupper width={500} title='Instrucciones de pago' style={{ marginBottom: 15 }} key={`pay-${a.payment_id}`}>
						{a.payment_method==20 ? (
							<InstructionsOxxo amount={a.total} reference={a.reference} download_url={DOWNLOAD_ALL} />	
						) : a.payment_method==40 ? (
							<InstructionsSPEI amount={a.total} download_url={DOWNLOAD_ALL} transfer_data={a.transfer_data} />	
						) : (
							<Header text='Error' />
						)}
					</Groupper>
				))
			)
		)}
		{/* <Groupper width={500} title='Boletos' style={{ marginTop: 15 }}>
			<Header text='Orden no pagada' subtext='Esta orden no se ha pagado, favor de realizar el pago para poder descargar los boletos' />
		</Groupper> */}
		<Table
			details
			titleCentered
			striped
			title='Datos de compra'
			style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
			data={[
				['Orden', order.order_hash],
				...(order.tickets.length>0 ? [
					['Boletos', order.tickets.length],
				] : []),
				['Estatus', <div className={classNames({
					green: order.paid,
					yellow: !order.paid
				})}>
					<Icon name={order.paid ? 'check circle' : 'clock'} />
					{order.paid ? 'Pagado' : 'Pendiente de pago'}
				</div>],
				['Fecha creación', moment.unix(order.date_created).format('DD/MMM/YY HH:mm')],
				['Fecha pago', order.paid ? moment.unix(order.date_paid).format('DD/MMM/YY HH:mm') : <i className='minus gray icon'></i>],
				null,
				...(order.first_name || order.last_name ? [
					['Nombre', `${(order.first_name || '').trim()} ${(order.last_name || '')}`]
				] : []),
				['Email', order.email],
			]}
		/>
	</div>
}

export default TicketDownload;